@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@layer components {
  .link {
    @apply  dark:text-slate-100 hover:text-blue-500 dark:hover:text-blue-500 focus:outline-none focus:outline-0 border-b-2 border-transparent;
  };

  .active {
    @apply text-stone-500 outline-none outline-0 hover:text-blue-500 border-b-2;
  };

  .linkbutton {
    @apply  dark:text-slate-100  focus:outline-none focus:outline-0;
  };

  .activebutton {
    @apply text-stone-500 outline-none outline-0 font-semibold text-lg text-left  ;
  };

  .linkfooter {
    @apply text-stone-500 hover:text-black dark:hover:text-slate-100 outline-none ;
  };

  .page {
    @apply
    w-screen
    row-start-3 row-end-4 
    backgroundColor animate-fadeInPage ease-in 
    /* font-poppins */
  };

  .page__title {
    @apply max-w-6xl mx-auto text-center dark:text-black text-2xl md:text-6xl
  }

  h1 {
    @apply  dark:text-slate-100 font-extrabold text-xl lg:text-5xl text-start py-4
  };

  h2 {
    @apply  dark:text-slate-100 font-bold text-lg lg:text-4xl text-start
  };

  h3 {
    @apply  dark:text-slate-100 font-bold text-base lg:text-3xl text-start
  };

  .input {
    @apply placeholder:opacity-50 placeholder:italic placeholder:font-semibold placeholder:text-slate-800 dark:placeholder:text-slate-400  dark:text-white font-medium text-lg italic
    w-full bg-transparent border-b border-b-slate-400
    hover:border-b-black
    dark:hover:border-b-white caret-black dark:caret-white 
  };

  .input:focus {
    @apply outline-none outline-current outline-0 border-b-black dark:border-b-white
  };

  .text {
    @apply  dark:text-slate-100 left-0 text-base lg:text-xl
  };

  .contactLink {
    @apply flex 300:flex-row items-start 300:items-center py-8 dark:text-slate-100 text-xs 300:text-sm lg:text-base space-y-6 300:space-y-0 300:space-x-4
  };

  .button {
    @apply border-black dark:border-white border-2 rounded-3xl border-current hover:border-blue-500 dark:hover:border-blue-500 dark:hover:text-black hover:bg-blue-500 font-semibold  dark:text-white text-lg text-left px-3
  };

  .button:focus {
    @apply outline-none outline-0
  };

  .tag {
    @apply  dark:text-slate-100 210:text-lg bg-blue-500 px-3 my-2 mr-2 rounded-xl pb-1
  };

  .logo {
    @apply w-32 300:w-48 my-16
  };

  .backgroundColor {
    @apply bg-teal-50 dark:bg-black
  }

  .bluegradient {
    @apply bg-gradient-to-r from-teal-600 to-blue-600
  }
};